import logo from './logo.svg';
import './App.css';
import Index from './pages/Index.js';
import Loading from './comps/Loading.js';
import Header from './comps/Header.js';
import Who from './comps/Who.js';
import Footer from './comps/Footer.js';
import About from './pages/About.js';
import F1 from './pages/F1.js';
import F2 from './pages/F2.js';
import Sync from './comps/Sync.js';



import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App bg-gradient-to-r from-[#E0E7EE] to-[#EFF6F6]">
       <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/whois-it-for" element={<Who />} /> 
          <Route path="/about" element={<About />} /> 

          <Route path="/advertisers" element={<F1 />} /> 
          <Route path="/drivers" element={<F2 />} /> 
          <Route path="/admin/sync" element={<Sync />} /> 

          </Routes>
          <Footer />

       </Router>
      <Loading />
    </div>
  );
}

export default App;
