import { useState } from "react";
const apiUrl = process.env.REACT_APP_API_URL;

const F2 = () => {
  const [formst,setFormst] = useState("");

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    city: "",
    state: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  var url =`${apiUrl}/api/drivers`;
  console.log(url)

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("raft")
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log("Form submitted successfully");
        setFormst("ok");

      } else {
        console.error("Form submission failed");
        setFormst("failed");

      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormst("failed");

    }
  };

  const onClose = () => {
    window.location.replace("/");
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <div className="mt-10 text-4xl">Be a Driver</div>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-2 gap-10 max-md:mx-5  md:w-[900px] border border-gray-300 p-20 my-10 rounded-3xl bg-white"
      >
        <div className="mb-5  max-md:col-span-2">
          <label
            htmlFor="first_name"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            First Name
          </label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="Your first name..."
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="mb-5 max-md:col-span-2">
          <label
            htmlFor="last_name"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            Last Name
          </label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Your last name..."
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="mb-5 max-md:col-span-2">
          <label
            htmlFor="city"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            City
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Your city..."
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="mb-5 max-md:col-span-2">
          <label
            htmlFor="state"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            State
          </label>
          <input
            type="text"
            name="state"
            id="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="Your state..."
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="mb-5 max-md:col-span-2">
          <label
            htmlFor="phone"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            Phone
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone number..."
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="mb-5 max-md:col-span-2">
          <label
            htmlFor="email"
            className="mb-3 block text-base font-medium text-gray-400 pl-2 text-left"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="user@example.com"
            className="w-full border border-gray-300 rounded-xl py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:shadow-md"
          />
        </div>

        <div className="max-md:col-span-2 flex flex-col items-start gap-10">

        <div className="flex flex-row-reverse justify-end gap-5">
          <button
            type="submit"
            className="hover:shadow-form bg-gray-900 py-3 px-8 text-base font-semibold text-white outline-none rounded-2xl hover:bg-gray-600 transition-all"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={onClose}
            className="hover:shadow-form bg-[#fff] py-3 px-8 text-base font-semibold text-black outline-none rounded-2xl hover:bg-gray-300 transition-all"
          >
            Back
          </button>
        </div>
        { formst === "failed" &&
        <p className="text-red-400 bg-gray-50 rounded-2xl px-4 py-2">Form submission failed</p>
        } { formst === "ok" &&
          <p className="text-green-400 bg-gray-50 rounded-2xl px-4 py-2">Form submitted successfully</p>
          }
        </div>
      </form>
    </div>
  );
};

export default F2;
