import { useEffect, useState } from "react";
const apiUrl = process.env.REACT_APP_API_URL;


const Sync = () => {
  const [showm,setShowm] = useState(false);
  const url = apiUrl; // URL مورد نظر را جایگزین کنید
  const formData = { key: "value" }; // فرم داده را جایگزین کنید

  const Syncing = async () => {
    console.log("Syncing started...");
    setShowm(true);
    try {
      const response = await fetch(`${url}/api/drivers/upload`, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json(); // اگر پاسخ JSON است
      console.log("Data received:", data);
    } catch (error) {
      console.error("Error syncing data:", error);
    }
    console.log("Form data:", formData);
  };



  return (
    <div className="flex justify-center items-center">
    <div className="flex flex-col md:flex-row justify-center items-center p-10 rounded-2xl border border-gray-200 m-10 bg-white gap-10">
      <h1>Click this button to instantly sync Excel files with Google Drive</h1>
      {!showm && <button className="btn" onClick={Syncing}>sync</button>}
      {showm && <button className="text-green-500">

        <svg width="30px" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
        
        </button>}
    </div>
    </div>
  );
};

export default Sync;
